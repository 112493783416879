const headers = [
  {
    text: 'Empresa',
    align: 'center',
    value: 'company',
    sortable: false,
    width: '47%'
  },
  {
    text: 'País',
    align: 'center',
    value: 'company.city.department.country.name',
    sortable: false,
    width: '13%'
  },
  {
    text: 'Pabellón',
    align: 'center',
    value: 'place',
    sortable: false,
    width: '20%'
  },
  {
    text: 'Stand',
    align: 'center',
    value: 'stand',
    sortable: false,
    width: '20%'
  }
]
export default headers
