<template>
  <div>
    <div class="pt-3" v-if="fair">
      <div class="row px-7" v-if="!showDetails">
        <div class="col-12 px-md-12 col-md-12 mt-4">
          <h2>Consulta de expositores {{ fair.name }}</h2>
        </div>
        <div class="col-12 px-md-12 col-md-3 text-center mt-4">
          <h4 class="secondary py-2 rounded">Criterios de búsqueda</h4>
        </div>
        <div class="col-12 text--primary px-md-12 pb-0">
          <div>
            <v-btn-toggle
              tile
              group
            >
              <v-btn value="companies" @click="clean('companies')" class="pl-0">
                Empresas
              </v-btn>
              <v-btn value="companyTypes" @click="clean('companyTypes')" class="pl-0">
                Tipo de empresa
              </v-btn>
              <v-btn value="productsAndServices" @click="clean('productsAndServices')" class="pl-0">
                Categoría y segmento
              </v-btn>
              <v-btn value="countries" @click="clean('countries')" class="pl-0">
                Países
              </v-btn>
              <v-btn value="places" @click="clean('places')" class="pl-0">
                Pabellones
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
        <div class="col-12 px-md-12 py-0">
          <div class="row" v-if="view === 'companies'">
            <div class="col-12 col-md-6">
              <v-text-field
                v-model="params.filter"
                label="Nombre empresa"
                v-on:keyup.enter="search"
                outlined
                dense
                single-line
                hide-details
              />
            </div>
          </div>

          <div class="row" v-if="view === 'companyTypes'">
            <div class="col-12 col-md-6">
              <v-select
                v-model="params.companyType"
                :items="companyTypes"
                item-text="name"
                item-value="id"
                outlined
                @change="search"
                dense
                hide-details
                label="Tipo de empresa"
              />
            </div>
          </div>

          <div class="row" v-if="view === 'countries'">
            <div class="col-12 col-md-6">
              <v-select
                v-model="params.country"
                :items="countries"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                @change="search"
                dense
                label="País"
              />
            </div>
          </div>

          <div class="row" v-if="view === 'places'">
            <div class="col-12 col-md-6">
              <v-select
                v-model="params.place"
                :items="places"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                @change="search"
                dense
                label="Pabellón"
              />
            </div>
          </div>

          <div class="row" v-if="view === 'productsAndServices'">
            <div class="col-12 col-md-6">
              <v-expansion-panels accordion v-model="panel">
                <v-expansion-panel
                  v-for="(category) in categories"
                  :key="category.id"
                >
                  <v-expansion-panel-header v-if="category.segments.length">{{
                      category.name
                    }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="category.segments.length">
                    <v-btn-toggle
                      tile
                      group
                      style="flex-direction: column; width: 100%"
                    >
                      <v-btn v-for="(segment) in category.segments"
                             :key="'seg-' + segment.id" :value="segment.id" @click="searchSegment(segment.id)">
                        {{ segment.name }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 d-flex justify-end">
              <v-btn
                color="primary"
                @click="search"
                class="white--text text-none elevation-0">
                <v-icon
                  left
                  dark>
                  fa-search
                </v-icon>
                Buscar
              </v-btn>
              <v-btn
                color="default"
                @click="clean('companies')"
                class="text-none elevation-0 ml-2">
                <v-icon
                  left
                  dark>
                  fa-broom
                </v-icon>
                Limpiar
              </v-btn>
            </div>
          </div>
        </div>
        <div class="col-12 mt-6 px-md-12">
          <div class="text-center" v-if="params">
            <data-table ref="expositors" :url="url" :params="params" :headers="headers">
              <template v-slot:item.company="{ item }">
                <div class="row">
                  <div class="col-12 pl-md-6 col-md-5 mt-4 mb-4 text-center">
                    <v-img :src="imageBaseUrl + item.company.logoUrl" style="cursor: pointer" contain
                           max-width="200" max-height="100" @click="showCompanyDetails(item)"/>
                  </div>
                  <div class="col-12 col-md-7 my-auto text-center">
                    <h3 class="text--primary" @click="showCompanyDetails(item)"
                        style="cursor: pointer">{{ item.company.name.toUpperCase() }}</h3>
                  </div>
                </div>
              </template>
              <template v-slot:item.place="{ item }">
                {{
                  uniqueArray(item)
                }}
              </template>
              <template v-slot:item.stand="{ item }">
                {{
                  item.stands.map(function (elem) {
                    return elem.code.toUpperCase();
                  }).join(', ')
                }}
              </template>
            </data-table>
          </div>
        </div>
      </div>
      <div class="row mt-4" v-else>
        <div class="col-12 px-md-12 col-md-6 mt-4 mb-4">
          <h2>Consulta de expositores {{ fair.name }}</h2>
        </div>
        <div class="col-12 col-md-6 mt-4 mb-4 text-right">
          <v-btn
            color="primary"
            @click="showDetails = false"
            class="text-none elevation-0 mr-4 mb-4 text-right">
            <v-icon
              left
              dark>
              fa-hand-point-left
            </v-icon>
            Regresar
          </v-btn>
        </div>
        <div class="col-12 col-md-10 offset-md-1">
          <v-card
            class="mx-auto"
            max-width="70%"
          >
            <div class="row">
              <div class="col-12 col-md-6 offset-md-3">
                <v-img
                  class="mx-8 align-center"
                  max-height="200"
                  max-width="350"
                  contain
                  :src="imageBaseUrl + item.company.logoUrl"
                />
              </div>
            </div>
            <v-card-title>{{ item.company.name.toUpperCase() }}</v-card-title>

            <v-card-text class="text--primary">
              <div class="row">
                <div class="col-6">
                  <h3>Pabellones: </h3>
                  <p>{{
                      uniqueArray(item)
                    }}
                  </p>
                </div>
                <div class="col-6">
                  <h3>Stands: </h3>
                  <p>{{
                      item.stands.map(function (elem) {
                        return elem.code.toUpperCase();
                      }).join(', ')
                    }}
                  </p>
                </div>
                <div class="col-6 pt-0">
                  <h3>Razón social: </h3>
                  <p>{{ item.company.businessName }}
                  </p>
                </div>
                <div class="col-6 pt-0">
                  <h3>Teléfono: </h3>
                  <p>{{ item.company.phone }}
                  </p>
                </div>
                <div class="col-6 pt-0">
                  <h3>Dirección: </h3>
                  <p>{{ item.company.address }}
                  </p>
                </div>
                <div class="col-6 pt-0">
                  <h3>Ciudad: </h3>
                  <p>{{ item.company.city.name }}
                  </p>
                </div>
                <div class="col-6 pt-0">
                  <h3>Departamento/Estado: </h3>
                  <p>{{ item.company.city.department.name }}
                  </p>
                </div>
                <div class="col-6 pt-0">
                  <h3>País: </h3>
                  <p>{{ item.company.city.department.country.name }}
                  </p>
                </div>
                <div class="col-6 pt-0">
                  <h3>E-mail: </h3>
                  <p>{{ item.company.email }}
                  </p>
                </div>
                <div class="col-6 pt-0">
                  <h3>Página web: </h3>
                  <a :href="getWebsiteUrl(item.company.webSite)" target="_blank" class="font-bold"
                     style="color: #13e23f">
                    {{ item.company.webSite }}
                  </a>
                </div>
                <div class="col-12 pt-0 text-justify">
                  <h3>Reseña: </h3>
                  <p>{{ item.company.spanishDescription }}
                  </p>
                </div>
                <div class="col-12">
                  <h3>Tipos de actividad: </h3>
                  <p>{{
                      item.company.activity_types.map(function (elem) {
                        return elem.name;
                      }).join(', ')
                    }}
                  </p>
                </div>
                <div class="col-12">
                  <h3 class="mb-2">Categorías: </h3>
                  <div v-for="category in item.company.categories" :key="category.id">
                    <p class="font-bold ml-4" v-if="category.name === 'Otro'">{{ category.name }}:
                      {{ category.pivot.description }}</p>
                    <p class="font-bold ml-4 mb-0" v-if="category.name !== 'Otro'">{{ category.name }}</p>
                    <p class="ml-4">{{
                        category.itemSegments.map(function (elem) {
                          return elem.name;
                        }).join(', ')
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-12">
                  <h3>Marcas que van a exhibir: </h3>
                  <p>{{
                      item.company.brands.map(function (elem) {
                        return elem.name;
                      }).join(', ')
                    }}
                  </p>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="my-0">
              <div class="row py-0">
                <div class="col-12 text-right py-0">
                  <v-btn
                    color="primary"
                    @click="showDetails = false"
                    class="text-none elevation-0 mr-4 mb-4">
                    <v-icon
                      left
                      dark>
                      fa-hand-point-left
                    </v-icon>
                    Regresar
                  </v-btn>
                </div>
              </div>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import {
  ACTIVITY_TYPES_URL,
  COUNTRIES_URL,
  EXPOSITORS_URL,
  PUBLIC_PLACE_URL,
  CATEGORIES_URL,
  PUBLIC_FAIRS_URL
} from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import DataTable from '@/components/data-table/DataTable'
import headers from '@/views/public/expositors/data/Headers'

export default {
  components: {
    DataTable
  },
  computed: {
    imageBaseUrl () {
      return process.env.VUE_APP_API_IMAGES_URL
    }
  },
  async created () {
    const fairSlug = this.$route.params.slug
    try {
      this.showLoader()
      await this.getFairBySlug(fairSlug)
      await this.getCompanyTypes()
      await this.getCountries()
      await this.getPlaces()
      await this.getCategories()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  data () {
    return {
      categories: [],
      companyTypes: [],
      countries: [],
      fair: null,
      headers,
      item: null,
      panel: null,
      params: {
        companyType: null,
        country: null,
        fairId: null,
        filter: null,
        pageSize: 10,
        place: null,
        segment: null
      },
      places: [],
      showDetails: false,
      view: 'companies',
      url: EXPOSITORS_URL
    }
  },
  methods: {
    clean (value) {
      this.view = value
      this.params.companyType = null
      this.params.country = null
      this.params.filter = null
      this.params.place = null
      this.params.segment = null
      this.panel = null
      this.search()
    },
    async getCountries () {
      const response = await this.get(COUNTRIES_URL)
      this.countries = response.data
    },
    async getFairBySlug (fairSlug) {
      const response = await this.getById(PUBLIC_FAIRS_URL, fairSlug)
      this.fair = response.data
      this.params.fairId = this.fair.id
    },
    async getCategories () {
      const response = await this.get(CATEGORIES_URL)
      this.categories = response.data
    },
    async getCompanyTypes () {
      const response = await this.get(ACTIVITY_TYPES_URL)
      this.companyTypes = response.data
    },
    async getPlaces () {
      const response = await this.get(PUBLIC_PLACE_URL + '/all', { params: { fairId: this.fair.id } })
      this.places = response.data
    },
    getWebsiteUrl (website) {
      if (!website.startsWith('http://') && !website.startsWith('https://')) {
        return 'http://' + website
      }
      return website
    },
    search () {
      this.$refs.expositors.getDataFromApi()
    },
    searchSegment (segmentId) {
      this.panel = null
      this.params.segment = segmentId
      this.search()
    },
    showCompanyDetails (item) {
      this.showDetails = true
      this.item = item
      this.item.company.categories.forEach((category) => {
        const segments = []
        category.segments.forEach((segment) => {
          this.item.company.segments.forEach((companySegment) => {
            if (segment.id === companySegment.id) {
              segments.push(segment)
            }
          })
        })
        category.itemSegments = segments
      })
    },
    uniqueArray (item) {
      const places = item.stands.map(function (elem) {
        return elem.placeName
      })
      const unique = [...new Set(places)]
      return unique.join(', ')
    }
  },
  mixins: [
    crudServiceMixin,
    loaderMixin,
    notificationMixin
  ],
  name: 'Expositors'
}
</script>
<style>
.v-data-table tr:hover:not(.v-table__expanded__content) {
  background: none !important;
}

table {
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
}

table th {
  background-color: #222c33;
  color: white !important;
  font-size: 17px !important;
  height: 40px !important;
}

table td + td {
  border-left: 1px solid #dddddd;
}
</style>
